import React from 'react'
import { graphql, PageProps, useStaticQuery } from 'gatsby'
import Layout from '../../../components/Layout'
import HeaderSEO from '../../../components/partials/HeaderSEO'
import { Section } from '../../../components/section'
import { SectionTitle } from '../../../components/section-title'
import * as stylesModule from './_styles.module.scss'
import { CardGroup } from '../../../components/card-group'
import { Card } from '../../../components/card'
import { AchievementGroup } from '../../../components/achievement-group'
import { Achievement } from '../../../components/achievement'
import { AchievementDescription } from '../../../components/achievement-description'
import { AchievementTitle } from '../../../components/achievement-title'
import { CaseStudyHero } from '../../../components/case-study-hero'
import { CaseStudyHeroTitle } from '../../../components/case-study-hero-title'
import { CaseStudyHeroSubtitle } from '../../../components/case-study-hero-subtitle'
import { Page } from '../../../components/page'
import { CaseStudyHeroImage } from '../../../components/case-study-hero-image'
import whatIfmediaHeroImage from '../../../resources/images/case_studies/what-if-media/hero.webp'
import { CardImage } from '../../../components/card-image'
import { CardTag } from '../../../components/card-tag'
import { CardTitle } from '../../../components/card-title'
import { Link } from 'gatsby'
import {
    achievements,
    featuredResources,
    companyPresentation,
    cardsDataPrefooter,
    preFooterTitle,
    tableOfContents,
    sectionIds,
    sectionKeys,
} from './_constants'
import { CardAuthor } from '../../../components/card-author'
import { ContentBlock } from '../../../components/content-block'
import { ContentBlockTitle } from '../../../components/content-block-title'
import { ContentBlockBody } from '../../../components/content-block-body'
import { Paragraph } from '../../../components/paragraph'
import { Blockquote } from '../../../components/blockquote'
import { CaseStudyContent } from '../../../components/case-study-content'
import { CaseStudyContentGroup } from '../../../components/case-study-content-group'
import { CaseStudyContentSidebar } from '../../../components/case-study-content-sidebar'
import { TableOfContents } from '../../../components/table-of-contents'
import { TableOfContentsTitle } from '../../../components/table-of-contents-title'
import { TableOfContentsList } from '../../../components/table-of-contents-list'
import { TableOfContentsListItem } from '../../../components/table-of-contents-list-item'
import { CompanyPresentation } from '../../../components/company-presentation'
import { CompanyPresentationList } from '../../../components/company-presentation-list'
import { CompanyPresentationListItem } from '../../../components/company-presentation-list-item'
import { CompanyPresentationPerson } from '../../../components/company-presentation-person'
import PreFooter from '../../../hanzo/lib/components/modules/prefooter'
import { TableOfContentsMenuBanner } from '../../../components/table-of-contents-menu-banner'
import { useSectionObserver } from '../../../utils/section-observer.hook'
import { BulletedList } from '../../../components/bulleted-list'
import { BulletedListItem } from '../../../components/bulleted-list-item'
import { Hyperlink } from '../../../components/hyperlink'

const whatIfMediaPage = ({ location }: PageProps) => {
    const styles: any = stylesModule
    const currentPath = location.pathname

    const { activeSection, sectionRefs } = useSectionObserver(sectionKeys)

    const data = useStaticQuery(graphql`
        query WhatIfMediaSEO {
            allContentfulPages(
                filter: {
                    urlPath: { eq: "/customers/what-if-media-innovates-multi-kafka-tooling" }
                }
            ) {
                edges {
                    node {
                        title {
                            title
                        }
                        keywords {
                            keywords
                        }
                        description {
                            description
                        }
                        oGimage {
                            fluid(quality: 100) {
                                src
                            }
                        }
                    }
                }
            }
        }
    `)

    const pageSEO = data.allContentfulPages.edges[0].node

    return (
        <Layout newsletter={false}>
            <HeaderSEO pageSEO={pageSEO} />
            <Page>
                <CaseStudyHero className={styles.heroSection}>
                    <CaseStudyHeroTitle>
                        <strong>What If Media Group</strong> innovates in AdTech with Multi-cluster
                        Kafka Tooling
                    </CaseStudyHeroTitle>
                    <CaseStudyHeroSubtitle>
                        From CLI to GUI to SQL for Kafka data exploration.
                    </CaseStudyHeroSubtitle>
                    <CaseStudyHeroImage
                        src={whatIfmediaHeroImage}
                        alt={'What If Media Group hero image'}
                    />
                </CaseStudyHero>

                <Section className={styles.inFocusSection}>
                    <SectionTitle>In focus</SectionTitle>
                    <AchievementGroup>
                        {achievements.map((achievement, index) => (
                            <Achievement key={index}>
                                <AchievementTitle>{achievement.title}</AchievementTitle>
                                <AchievementDescription>
                                    {achievement.description}
                                </AchievementDescription>
                            </Achievement>
                        ))}
                    </AchievementGroup>

                    <CaseStudyContent>
                        <CaseStudyContentSidebar>
                            <TableOfContentsMenuBanner title={'Table of contents'}>
                                <TableOfContentsList>
                                    {tableOfContents.map((item, index) => (
                                        <TableOfContentsListItem
                                            key={index}
                                            active={item.key === activeSection}>
                                            <Link to={`${currentPath}${item.id}`}>
                                                {item.title}
                                            </Link>
                                        </TableOfContentsListItem>
                                    ))}
                                </TableOfContentsList>
                            </TableOfContentsMenuBanner>
                            <TableOfContents>
                                <TableOfContentsTitle>Table of contents</TableOfContentsTitle>
                                <TableOfContentsList>
                                    {tableOfContents.map((item, index) => (
                                        <TableOfContentsListItem
                                            key={index}
                                            active={item.key === activeSection}>
                                            <Link to={`${currentPath}${item.id}`}>
                                                {item.title}
                                            </Link>
                                        </TableOfContentsListItem>
                                    ))}
                                </TableOfContentsList>
                            </TableOfContents>
                            <CompanyPresentation>
                                <CompanyPresentationPerson
                                    name={companyPresentation.person.name}
                                    position={companyPresentation.person.position}
                                    imageSrc={companyPresentation.person.imageSrc}
                                    imageAlt={companyPresentation.person.imageAlt}
                                />
                                {companyPresentation.lists.map((list, index) => (
                                    <CompanyPresentationList title={list.title} key={index}>
                                        {list.items.map((item, index) => (
                                            <CompanyPresentationListItem key={index}>
                                                {item}
                                            </CompanyPresentationListItem>
                                        ))}
                                    </CompanyPresentationList>
                                ))}
                            </CompanyPresentation>
                        </CaseStudyContentSidebar>
                        <CaseStudyContentGroup id={'case-study-content-group'}>
                            <ContentBlock ref={sectionRefs[sectionKeys.section1]}>
                                <ContentBlockTitle id={sectionIds.section1}>
                                    Real-time data revolutionizing AdTech
                                </ContentBlockTitle>
                                <ContentBlockBody>
                                    <Paragraph>
                                        <Hyperlink href={'https://whatifmediagroup.com/'}>
                                            What If Media Group
                                        </Hyperlink>{' '}
                                        stands at the forefront of AdTech innovation, where
                                        real-time data processing has become the cornerstone of
                                        success. As consumer expectations evolve, the ability to
                                        deliver hyper-personalized messages at the exact right
                                        moment has become essential for performance marketing.
                                    </Paragraph>
                                    <Paragraph>
                                        Their platform{' '}
                                        <Hyperlink href={'https://whatifmediagroup.com/platform/'}>
                                            ARIA
                                        </Hyperlink>{' '}
                                        builds universal user profiles by analyzing behavior across
                                        web, mobile, and voice, all in real-time. Acting as a
                                        “hivemind of models,” it predicts the best ad to show, on
                                        the right channel, at the perfect moment. This powers
                                        dynamic bidding driven by AI and streaming data - where
                                        milliseconds and conversion confidence determine success.
                                    </Paragraph>
                                </ContentBlockBody>
                            </ContentBlock>

                            <ContentBlock ref={sectionRefs[sectionKeys.section2]}>
                                <ContentBlockTitle id={sectionIds.section2}>
                                    The rapid growth of Kafka
                                </ContentBlockTitle>
                                <ContentBlockBody>
                                    <Paragraph>
                                        Real-time data processing for hyperpersonalized customer
                                        acquisition, at scale, is a great example of what Apache
                                        Kafka was built for. What If Media Group recognized this
                                        early, standardizing on Kafka as the foundation of data
                                        movement across their business and different services,
                                        allowing them to build cutting-edge applications.
                                    </Paragraph>
                                    <Paragraph>
                                        Running multiple Kafka clusters is important for What If
                                        Media. They needed to host different applications on
                                        different clusters. This was to isolate workloads but also
                                        to give more autonomy to different teams, so they could run
                                        their own infrastructure.
                                    </Paragraph>
                                    <Paragraph>
                                        Their growth has been remarkable: starting with a single
                                        Kafka cluster for processing leads for their insurance
                                        customers, they&apos;ve scaled to 15 clusters accessed by
                                        more than 40 software, data, and AI engineers. Like many
                                        businesses, What If Media Group found themselves adopting
                                        different Kafka vendors and deployments over time, through
                                        choice and acquisitions - from open-source Kafka deployed on
                                        ECS and AWS MSK, to Strimzi on Kubernetes and Confluent.
                                    </Paragraph>
                                    <Blockquote
                                        citation={
                                            'Ryan Anguiano, Staff Architect, What If Media Group'
                                        }>
                                        “The more applications we can modernize to process real-time
                                        data, the better our offering becomes for customers”
                                    </Blockquote>
                                    <Paragraph>
                                        Their leadership philosophy centers on engineering autonomy
                                        as the engine of innovation. By giving developers and data
                                        scientists direct access to real-time data streams, they can
                                        create an environment where products can be created and
                                        improved without reliance on a centralized team.
                                    </Paragraph>
                                </ContentBlockBody>
                            </ContentBlock>

                            <ContentBlock ref={sectionRefs[sectionKeys.section3]}>
                                <ContentBlockTitle id={sectionIds.section3}>
                                    Opening up data across a complex, multi-cluster ecosystem
                                </ContentBlockTitle>
                                <ContentBlockBody>
                                    <Paragraph>
                                        Despite their success with Kafka, they faced significant
                                        challenges managing their expanding infrastructure and
                                        maintaining developer productivity. With 15 Kafka clusters
                                        running across different vendors and platforms, maintaining
                                        unified governance and developer experience became
                                        increasingly difficult. Having data siloed in different
                                        clusters was good architecturally, but made it particularly
                                        difficult to foster a culture of innovation and data sharing
                                        across teams:
                                    </Paragraph>
                                    <Blockquote
                                        citation={
                                            'Ryan Anguiano, Staff Architect, What If Media Group'
                                        }>
                                        “If you&apos;re able to{' '}
                                        <Hyperlink
                                            href={
                                                'https://lenses.io/blog/2024/12/data-mesh-data-streaming-apache-kafka/'
                                            }>
                                            discover & explore real-time data
                                        </Hyperlink>{' '}
                                        you didn’t know existed, from another team, then you can
                                        introduce a culture of innovation creating amazing
                                        applications that otherwise would never have been possible”
                                    </Blockquote>
                                    <Paragraph>
                                        Troubleshooting streaming applications across different
                                        clusters was particularly painful. Engineers had to dig
                                        through command line interfaces, looking up multiple
                                        commands just to find specific messages in a topic. What
                                        should have been quick fixes often turned into hours-long
                                        investigations.
                                    </Paragraph>
                                    <Paragraph>
                                        The company faced a critical question: How could they
                                        empower all their engineers and data scientists to work
                                        effectively with real-time data while maintaining governance
                                        across different clusters?
                                    </Paragraph>
                                </ContentBlockBody>
                            </ContentBlock>

                            <ContentBlock ref={sectionRefs[sectionKeys.section4]}>
                                <ContentBlockTitle id={sectionIds.section4}>
                                    Democratizing streaming data across the organization
                                </ContentBlockTitle>
                                <ContentBlockBody>
                                    <Paragraph>
                                        What If Media Group implemented{' '}
                                        <Hyperlink href={'https://lenses.io/product/'}>
                                            Lenses Developer Experience
                                        </Hyperlink>{' '}
                                        to create a single interface across their 15 Kafka clusters,
                                        dramatically changing how their teams interact with data:
                                    </Paragraph>
                                    <Blockquote
                                        citation={'John Pershing, CTO, What If Media Group'}>
                                        “Lenses aligns perfectly with our strategy of empowering
                                        engineers with high productivity tools that accelerate
                                        development. Just like our adoption of AI-powered
                                        engineering tools, Lenses helps our teams work smarter, not
                                        harder, across our Kafka ecosystem”
                                    </Blockquote>
                                    <Paragraph>
                                        This approach transformed Kafka from a tool used by a
                                        handful of specialists to a source of innovation accessible
                                        to their entire engineering organization. Ryan Anguiano
                                        describes Lenses as “the stairs” that help developers climb
                                        the Kafka learning curve. “It&apos;s not just 5 highly
                                        specialized engineers that can work with data in Kafka
                                        anymore – it&apos;s 40+ engineers and data scientists”,
                                        Anguiano explains. “This fundamentally changes our
                                        business.”
                                    </Paragraph>
                                    <Paragraph>
                                        <Hyperlink
                                            href={
                                                'https://lenses.io/blog/2024/10/sql-kafka-data-exploration/'
                                            }>
                                            Lenses Global SQL Studio
                                        </Hyperlink>{' '}
                                        was particularly useful; developers could use familiar
                                        syntax to find messages across any cluster. The governance
                                        capabilities underpinning this decentralized approach allow
                                        teams to collaborate effectively while maintaining
                                        appropriate access controls and visibility across their
                                        technology stack.
                                    </Paragraph>
                                    <Paragraph>
                                        “Using{' '}
                                        <Hyperlink
                                            href={
                                                'https://lenses.io/blog/2024/10/sql-kafka-data-exploration/'
                                            }>
                                            Lenses SQL
                                        </Hyperlink>{' '}
                                        over our multiple Kafka clusters is a way to drive product
                                        innovation and create new applications that wouldn’t
                                        otherwise be possible. Engineers can now write a query to
                                        search across Kafka environments to find and work with data
                                        they wouldn’t otherwise have had access to. We can open up
                                        our imagination and our opportunities with the data we
                                        have”, Anguiano adds.
                                    </Paragraph>
                                    <Paragraph>
                                        What If Media Group prides themselves on empowering their
                                        engineers to be autonomous and creative with data. In
                                        today&apos;s rapidly evolving tech landscape, their
                                        leadership recognizes that equipping engineers with
                                        cutting-edge tools is essential for staying competitive:
                                    </Paragraph>
                                    <Blockquote
                                        citation={'John Pershing, CTO, What If Media Group'}>
                                        “Any CTO needs to invest in tooling such as Lenses to drive
                                        engineering productivity, simplify workflows, maintain
                                        security and governance, and most importantly, deliver
                                        cutting-edge AI systems to stay relevant”
                                    </Blockquote>
                                </ContentBlockBody>
                            </ContentBlock>

                            <ContentBlock ref={sectionRefs[sectionKeys.section5]}>
                                <ContentBlockTitle id={sectionIds.section5}>
                                    Faster innovation and strategic growth
                                </ContentBlockTitle>
                                <ContentBlockBody>
                                    <Paragraph>
                                        The impact on What If Media Group&apos;s operations has been
                                        transformative. Across the organization, five different
                                        teams have adopted Kafka with Lenses for their specific
                                        needs:
                                    </Paragraph>

                                    <BulletedList>
                                        <BulletedListItem>
                                            The voice & leads team processes web leads and routes
                                            phone calls to insurance agents
                                        </BulletedListItem>
                                        <BulletedListItem>
                                            The website team manages consumer-facing sites that
                                            generate leads
                                        </BulletedListItem>
                                        <BulletedListItem>
                                            The messaging team reads events to trigger direct
                                            marketing across various channels, using AI models to
                                            generate personalized content
                                        </BulletedListItem>
                                        <BulletedListItem>
                                            The delivery team handles email processing
                                        </BulletedListItem>
                                        <BulletedListItem>
                                            The data engineering team supports all teams with data
                                            movement and transforms
                                        </BulletedListItem>
                                    </BulletedList>

                                    <Paragraph>
                                        Troubleshooting that once took hours now takes minutes. “You
                                        might spend hours looking up commands, typing them in,
                                        fixing spelling errors... Lenses just gives you the ability
                                        to visually see everything at the same time and pick up on
                                        cues”, says Ryan Anguiano.
                                    </Paragraph>
                                    <Paragraph>
                                        Looking ahead, What If Media Group is consolidating their
                                        infrastructure by migrating their clusters to Strimzi on
                                        Kubernetes. This transition offers the ideal balance between
                                        control and ease of management without disrupting developer
                                        workflows, as Lenses works consistently across any Kafka
                                        deployment.
                                    </Paragraph>
                                    <Paragraph>
                                        “Strimzi gives you the same level of control as running
                                        Kafka on your own EC2 instances but with minimal config. It
                                        replaces what was an entire repo with a single configuration
                                        file”, notes Anguiano.
                                    </Paragraph>
                                </ContentBlockBody>
                            </ContentBlock>

                            <ContentBlock ref={sectionRefs[sectionKeys.section6]}>
                                <ContentBlockTitle id={sectionIds.section6}>
                                    Scaling real-time data processing
                                </ContentBlockTitle>
                                <ContentBlockBody>
                                    <Paragraph>
                                        With their Kafka clusters now unified under a single pane of
                                        glass, What If Media Group is ready to tackle more ambitious
                                        data processing goals. They plan to use Lenses SQL
                                        Processors to move ETL operations into their real-time
                                        streaming pipeline, shifting data processing left so it is
                                        performed closer to the source.
                                    </Paragraph>
                                    <Blockquote
                                        citation={
                                            'Ryan Anguiano, Staff Architect, What If Media Group'
                                        }>
                                        “A lot of ETL happens very late in our pipelines, which
                                        isn&apos;t efficient. We want to start doing streaming data
                                        processing instead and get that into the pipeline”
                                    </Blockquote>
                                    <Paragraph>
                                        This strategic shift will provide consistent, timely,
                                        reliable access to important business data where and when it
                                        is needed – powering the next generation of AI-driven
                                        marketing solutions and cementing What If Media Group&apos;s
                                        position as a leader in performance marketing innovation.
                                    </Paragraph>
                                </ContentBlockBody>
                            </ContentBlock>
                        </CaseStudyContentGroup>
                    </CaseStudyContent>
                </Section>

                <Section className={styles.featuredResourcesSection}>
                    <SectionTitle>Featured Resources</SectionTitle>
                    <CardGroup>
                        {featuredResources.map((featuredResource, index) => (
                            <Card key={index}>
                                <CardImage
                                    src={featuredResource.imageSrc}
                                    alt={featuredResource.imageAlt}
                                />
                                <CardTag>{featuredResource.tag}</CardTag>
                                <CardTitle>
                                    <Link to={featuredResource.link}>{featuredResource.title}</Link>
                                </CardTitle>
                                <CardAuthor
                                    imageSrc={featuredResource.author.imageSrc}
                                    imageAlt={featuredResource.author.imageAlt}
                                    name={featuredResource.author.name}
                                />
                            </Card>
                        ))}
                    </CardGroup>
                </Section>

                <section className={styles.ctaSection}>
                    <PreFooter title={preFooterTitle} cardsData={cardsDataPrefooter} />
                </section>
            </Page>
        </Layout>
    )
}

export default whatIfMediaPage
