import React from 'react'
import thumbnailStateOfKafkaReplicationImage from '../../../resources/images/featured_resources/thumbnails/state-of-kafka-replication.webp'
import thumbnailLenses6PanoptesImage from '../../../resources/images/featured_resources/thumbnails/lenses-6-0-panoptes.webp'
import thumbnailPressReleaseImage from '../../../resources/images/featured_resources/thumbnails/press-release.webp'
import authorAndrewStevensonImage from '../../../resources/images/featured_resources/authors/andrew-stevenson.webp'
import authorLensesImage from '../../../resources/images/featured_resources/authors/lenses.webp'
import { DemoIcon, DownloadBigIcon } from '../../../hanzo/lib/components/basics/icons'
import { Type } from '../../../hanzo/pages/home/constants'
import ryanAnguianoImage from '../../../resources/images/case_studies/what-if-media/ryan-anguiano.webp'

export const achievements = [
    {
        title: '-50% decreased Kafka onboarding time',
        description: 'Lenses creates an on-ramp for getting new Kafka users up to speed',
    },
    {
        title: '15 Kafka clusters',
        description: 'Available for self-service exploration by 40 engineers',
    },
    {
        title: 'Decreased time‑to‑market',
        description: 'Of real-time streaming applications',
    },
]

export const sectionIds = {
    section1: 'the-change-in-the-world',
    section2: 'the-new-potential',
    section3: 'the-obstacle',
    section4: 'the-breakthrough',
    section5: 'the-pay-off',
    section6: 'what-s-next',
}

export const sectionKeys = {
    section1: 'section1',
    section2: 'section2',
    section3: 'section3',
    section4: 'section4',
    section5: 'section5',
    section6: 'section6',
}

export const tableOfContents = [
    { key: sectionKeys.section1, title: 'The change in the world', id: `#${sectionIds.section1}` },
    { key: sectionKeys.section2, title: 'The new potential', id: `#${sectionIds.section2}` },
    { key: sectionKeys.section3, title: 'The obstacle', id: `#${sectionIds.section3}` },
    { key: sectionKeys.section4, title: 'The breakthrough', id: `#${sectionIds.section4}` },
    { key: sectionKeys.section5, title: 'The pay off', id: `#${sectionIds.section5}` },
    { key: sectionKeys.section6, title: "What's next", id: `#${sectionIds.section6}` },
]

export const companyPresentation = {
    person: {
        name: 'Ryan Anguiano',
        position: 'Staff Architect & Kafka Evangelist, What If Media Group',
        imageSrc: ryanAnguianoImage,
        imageAlt: 'Ryan Anguiano - avatar image',
    },
    lists: [
        {
            title: 'Infrastructure',
            items: ['15 clusters running on EC2', 'MSK', 'and migrating to Strimzi on Kubernetes'],
        },
        {
            title: 'Integrations',
            items: ['Snowflake', 'S3'],
        },
        {
            title: 'Lenses Features',
            items: ['SQL Studio', 'Kafka Connectors', 'Topology', 'Alerts', 'Consumer Monitoring'],
        },
    ],
}

export const featuredResources = [
    {
        imageSrc: thumbnailStateOfKafkaReplicationImage,
        imageAlt: 'The state of Kafka replication - thumbnail image',
        tag: 'Blog',
        title: 'The state of Kafka replication',
        link: '/blog/2025/02/state-of-kafka-replication/',
        author: {
            imageSrc: authorAndrewStevensonImage,
            imageAlt: 'Andrew Stevenson - avatar image',
            name: 'Andrew Stevenson',
        },
    },
    {
        imageSrc: thumbnailLenses6PanoptesImage,
        imageAlt: 'Introducing Lenses 6.0 Panoptes - thumbnail image',
        tag: 'Blog',
        title: 'Introducing Lenses 6.0 Panoptes',
        link: '/blog/2024/11/lenses-6-panoptes-announcement/',
        author: {
            imageSrc: authorAndrewStevensonImage,
            imageAlt: 'Andrew Stevenson - avatar image',
            name: 'Andrew Stevenson',
        },
    },
    {
        imageSrc: thumbnailPressReleaseImage,
        imageAlt: 'Lenses.io introduces new streaming data replicator - thumbnail image',
        tag: 'Press release',
        title: 'Lenses.io introduces new streaming data replicator',
        link: '/blog/2025/02/introducing-streaming-data-replicator/',
        author: {
            imageSrc: authorLensesImage,
            imageAlt: 'Lenses.io - avatar image',
            name: 'Lenses.io',
        },
    },
]

export const preFooterTitle = 'Try Lenses for yourself'

export const cardsDataPrefooter = [
    {
        title: (
            <>
                20 minute
                <br />
                demo
            </>
        ),
        type: Type.Primary,
        description: [
            'Get a guided tour of Lenses',
            'Receive a trial key to install on your clusters',
            'Perfect for production and large-scale streaming deployments.',
        ],
        buttonText: 'Get a demo',
        href: '/request-demo',
        icon: <DemoIcon key='demoIcon' />,
    },
    {
        title: 'Free Lenses',
        leadingText: 'Run the docker command to get Lenses Community Edition:',
        type: Type.Secondary,
        description: [
            'Apache Kafka Docker + Lenses 6.0',
            'Pre-loaded synthetic data',
            'Up to 2 users + environments',
        ],
        buttonText: 'Download',
        href: '/community-edition/',
        icon: <DownloadBigIcon key='downloadBigIcon' />,
    },
]
